const DEV_API_URL = process.env.REACT_APP_DEV_API_URL;
const TEST_API_URL = process.env.REACT_APP_TEST_API_URL;
const PREPROD_API_URL = process.env.REACT_APP_PREPROD_API_URL;
const PROD_API_URL = process.env.REACT_APP_PROD_API_URL;
const SEMIPROD_API_URL = process.env.REACT_APP_SEMIPROD_API_URL;
const aapuat_API_URL = process.env.REACT_APP_aapuat_API_URL;

export const ApiConfig = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return DEV_API_URL;
    case "test":
      return TEST_API_URL;
    case "preProduction":
      return PREPROD_API_URL;
    case "production":
      return PROD_API_URL;
    case "SEMIPROD":
      return SEMIPROD_API_URL;  
    case "aapuat":
      return aapuat_API_URL;
    default:
      return DEV_API_URL;
  }
};
